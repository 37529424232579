import React, { useState } from 'react';
import "./Register.scss"
import arrowTitle from '../../assets/arrow_title.svg'
import Input from '../../components/input/Input'
import { useDispatch, useSelector } from 'react-redux';
import { registerThunk } from '../../redux/auth/thunks';
import Backdrop from "../../components/backdrop/Backdrop"
import Toast from "../../components/toast/Toast"
import { hideToast } from '../../redux/auth/authSlice';
import FormData from 'form-data';

const formFields = [
    { name: "name", type: "text", placeholder: "Nombre", columnSize: "is-6", required: true },
    { name: "lastName", type: "text", placeholder: "Apellido", columnSize: "is-6", required: true },
    { name: "email", type: "text", placeholder: "Email", columnSize: "is-6", required: true },
    { name: "dni", type: "text", placeholder: "DNI", columnSize: "is-6", required: true },
    { name: "password", type: "password", placeholder: "Contraseña", columnSize: "is-6", required: true },
    { name: "password2", type: "password", placeholder: "Repetir Contraseña", columnSize: "is-6", required: true },
    { name: "genre", type: "select", placeholder: "Género", columnSize: "is-6", required: true, options: [{ value: "m", label: "Masculino" }, { value: "f", label: "Femenino" }] },
    { name: "phone", type: "text", placeholder: "Celular", columnSize: "is-6", required: true },
    { name: "dateOfBirth", type: "date", placeholder: "Fecha de Nacimiento", columnSize: "is-6", required: true },
    { name: "address", type: "text", placeholder: "Dirección", columnSize: "is-6", required: true },
    { name: "country", type: "text", placeholder: "País", columnSize: "is-6", required: true },
    { name: "province", type: "text", placeholder: "Provincia", columnSize: "is-6", required: true },
    { name: "zone", type: "text", placeholder: "Localidad", columnSize: "is-6", required: true },
    { name: "postalCode", type: "text", placeholder: "Código Postal", columnSize: "is-6", required: true },
    { name: "title", type: "text", placeholder: "Título", columnSize: "is-6", required: true },
    { name: "profession", type: "text", placeholder: "Profesión", columnSize: "is-6", required: true },
    { name: "specialty", type: "text", placeholder: "Especialidad", columnSize: "is-12", required: true },
    { name: "jobAddress", type: "text", placeholder: "Lugar de trabajo", columnSize: "is-12", required: true },
    { name: "cv", type: "file", placeholder: "Cargar CV", columnSize: "is-12", required: true },
    { name: "aval", type: "text", placeholder: "Aval", columnSize: "is-12", conditional: true, required: false }
];

export default function Register() {
    const [data, setData] = useState({});
    const [becomeMember, setBecomeMember] = useState(false);
    const [resident, setResident] = useState(false);
    const [invalidFields, setInvalidFields] = useState([]);
    const dispatch = useDispatch();
    const loading = useSelector(state => state.auth.loading);
    const error = useSelector(state => state.auth.error);
    const message = useSelector(state => state.auth.message);
    const [file, setFile] = useState(null);

    const fileSelectedHandler = (e) => {
        setFile({ file: e.target.files[0], name: e.target.name });
    }

    const onChangeMember = (e) => {
        setBecomeMember(e.target.checked);
    }

    const onChangeResident = (e) => {
        setResident(e.target.checked);
    }

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    };

    const fieldsValidate = () => {
        let invalids = [];
        formFields.forEach(field => {
            if (field.required) {
                if (field.type === 'file') {
                    if (!file) {
                        invalids.push(field.name);
                    }
                } else {
                    if (!data[field.name]) {
                        invalids.push(field.name);
                    }
                }
            }
        });
        if (data.password !== data.password2) {
            invalids.push("passwordMismatch");
        }
        if (invalids.length > 0) {
            setInvalidFields(invalids);
            alert("Por favor completa todos los campos obligatorios.");
            return false;
        }
        setInvalidFields([]);
        return true;
    }

    const register = (event) => {
        event.preventDefault();
        if (!fieldsValidate()) {
            return;
        }

        data.resident = resident;
        let form_data = new FormData();

        if (file) {
            form_data.append(file.name, file.file);
        }
        Object.keys(data).forEach(function (key) {
            form_data.append(key, data[key]);
        });
        dispatch(registerThunk(form_data));
    };

    const handleToast = () => {
        dispatch(hideToast());
    }

    const isInvalidField = (fieldName) => {
        return invalidFields.includes(fieldName);
    };

    return (
        <>
            <main id='register'>
                <section className='section'>
                    <div className="columns is-centered">
                        <div className="column is-4">
                            <h1 className="titleProp">
                                <span className='title-border-white'>REGISTRO</span> <br />
                                <img src={arrowTitle} alt="" width="50px" />
                            </h1>
                        </div>
                        <form className="column is-8">
                            <div className="field column is-12 header-form">
                                <div style={{ fontSize: 25, paddingLeft: '3%' }} className="control">
                                    <label className="radio mb-3 has-text-white">
                                        <input type="checkbox"
                                            checked={resident}
                                            onChange={onChangeResident} />
                                        Soy Residente
                                    </label>
                                </div>
                            </div>
                            <div className="columns is-multiline is-centered">
                                {formFields.map(field => (
                                    (!field.conditional || (field.conditional && becomeMember)) && (
                                        <div key={field.name} className={`field column ${field.columnSize}`}>
                                            <div className="control">
                                                {field.type === "select" ? (
                                                    <>
                                                        <label style={{ color: 'white', fontSize: 20 }}>
                                                            {field.placeholder}{field.required && "*"}
                                                        </label>
                                                        <select
                                                            style={{
                                                                width: '100%',
                                                                minHeight: '50px',
                                                                justifyContent: 'center',
                                                                backgroundColor: 'white',
                                                                border: isInvalidField(field.name) ? '1px solid red' : 'none',
                                                                fontSize: '1.3rem',
                                                                outline: 'none',
                                                                color: '#5d677f',
                                                                paddingLeft: '10px',
                                                            }}
                                                            onChange={handleChange}
                                                            value={data[field.name] || ""}
                                                            name={field.name}
                                                        >
                                                            <option hidden></option>
                                                            {field.options.map(option => (
                                                                <option key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </>
                                                ) : (
                                                    field.type === "file" ? (
                                                        <Input
                                                            type={field.type}
                                                            placeholder={`${field.placeholder}${field.required ? " *" : ""}`}
                                                            handleChange={fileSelectedHandler}
                                                            // No se pasa el valor para input type="file"
                                                            name={field.name}
                                                            isInvalid={isInvalidField(field.name)}
                                                        />
                                                    ) : (
                                                        <Input
                                                            type={field.type}
                                                            placeholder={`${field.placeholder}${field.required ? " *" : ""}`}
                                                            handleChange={handleChange}
                                                            value={data[field.name] || ""}
                                                            name={field.name}
                                                            isInvalid={isInvalidField(field.name)}
                                                        />
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )
                                ))}
                            </div>
                            <div style={{ paddingBottom: '5%' }} className="field column is-12 fot-register">
                                <button
                                    onClick={register}
                                    className="button is-fullwidth">
                                    Registrarme
                                </button>
                            </div>
                        </form>
                    </div>
                </section>
            </main>
            <Toast visible={error ? true : false} handleToast={handleToast} message={message} title={"Registro"} />
            <Backdrop show={loading} />
        </>
    )
}
